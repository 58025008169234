<template>
  <!--PASS快速开发平台工具 3个值-->

  <div class="zj_passData flexrow flexlraround">

    <div
      class="passd_part flexrow wow bounceIn"
      v-for="item in passData"
      :key="item.index"
      :class="item.index == 1 ? 'marl15' : 'marl0'"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1"
    >
      <div class="pic flexcc">
        <img :src="item.icon" />
      </div>
      <div class="name flextbc black font12rem">{{ item.name }}</div>
    </div>


   <div
      class="passd_part flexrow wow bounceIn"
      v-for="item in passData2"
      :key="item.index"
      :class="item.index == 1 ? 'marl15' : 'marl0'"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1"
    >
      <div class="pic flexcc">
        <img :src="item.icon" />
      </div>
      <div class="name flextbc black font12rem">{{ item.name }}</div>
    </div>


  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_passData",
  data() {
    return {
      passData: [
        {
          index: 1,
          name: this.$t("数据展示风格单一"),
          icon: require("../../assets/Images/smpoint.png"),
        },
        {
          index: 2,
          name: this.$t("产品体验不佳"),
          icon: require("../../assets/Images/smpoint.png"),
        },
        {
          index: 3,
          name: this.$t("视觉设计和交互能力弱"),
          icon: require("../../assets/Images/smpoint.png"),
        }
      ],
        passData2: [
      
         {
          index: 4,
          name: this.$t("数据反馈不及时"),
          icon: require("../../assets/Images/smpoint.png"),
        },
         {
          index: 5,
          name: this.$t("数据信息不互通"),
          icon: require("../../assets/Images/smpoint.png"),
        },
         {
          index: 6,
          name: this.$t("决策管理无数据依据"),
          icon: require("../../assets/Images/smpoint.png"),
        },
      ],
    };
  },
  watch: {
    '$i18n.locale': function (newVal) {
        this.passData= [
        {
          index: 1,
          name: this.$t("数据展示风格单一"),
          icon: require("../../assets/Images/smpoint.png"),
        },
        {
          index: 2,
          name: this.$t("产品体验不佳"),
          icon: require("../../assets/Images/smpoint.png"),
        },
        {
          index: 3,
          name: this.$t("视觉设计和交互能力弱"),
          icon: require("../../assets/Images/smpoint.png"),
        }
      ]
      this.passData2 = [
      
      {
       index: 4,
       name: this.$t("数据反馈不及时"),
       icon: require("../../assets/Images/smpoint.png"),
     },
      {
       index: 5,
       name: this.$t("数据信息不互通"),
       icon: require("../../assets/Images/smpoint.png"),
     },
      {
       index: 6,
       name: this.$t("决策管理无数据依据"),
       icon: require("../../assets/Images/smpoint.png"),
     },
   ]
   }
  },
  mounted() {
    new this.$wow.WOW({ live: true }).init();
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.cnname {
    width: 100%;
    margin-bottom: 1rem;
  }
.zj_passData {
  width: 100%;
  height: 70%;
  margin: 0 auto;
  .marl15 {
    margin-left: 15%;
  }
  .marl0 {
    margin-left: 0%;
  }
  .passd_part {
    width: 30%;
    height: 100%;
    
    .pic {
      width: 5%;
      height: 100%;
      img {
        width: 100%;
      }
    }
    .name {
      width: 75%;
      height: 100%;
      font-size: 1rem;
      margin-left: 5%;
    }
  }
}
</style>
