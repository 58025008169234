<template>
  <!--零售数字化移动BI简介-->
  <div class="zj_productIntroduce">
      <div
      class="cnname flexcc font16rem black wow bounceIn"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1"
    >
     {{$t("行业应用及应用场景")}}
    </div>
    <div
      class="nameline flexcc wow bounceIn"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1"
    >
      <div class="line"></div>
    </div>
    <div class="zj_inside flexrow">
      <div class="detail wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div class="line1 flextbc">
          <img src="../../assets/Images/ks_td1.png" />
        </div>
        <div class="line2 font14rem fontweight black">{{$t("1.实时汇报")}}</div>
        <div :class="$i18n.locale == 'CN'?'line3 font8rem black2':'line3EN font8rem black2'">
          {{$t("交互式实时数据可视化大屏，实时监测企业数据，洞悉运营增长，助力智能高效决策。多源适配，可视化建模，配合强大灵活的数据分析能力，轻松实现秒级数据响应，实时发现异常。数据监控，安全预警，异常发现，多种实时场景，灵活适配：")}}
        </div>
        <div :class="$i18n.locale == 'CN'?'line4 font14rem fontweight black':'line4EN font14rem fontweight black'">{{$t("（1）多方位、多角度、全景展现企业的各项指标")}}</div>
         <div :class="$i18n.locale == 'CN'?'line4 font14rem fontweight black':'line4EN font14rem fontweight black'">{{$t("（2）数据实时监控，企业动态一目了然")}}</div>
          <div :class="$i18n.locale == 'CN'?'line4 font14rem fontweight black':'line4EN font14rem fontweight black'">{{$t("（3）个性化定制，布局灵活，样式丰富")}}</div>
       
      </div>
      <div class="pic wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      
        <div class="case flexcc">
          <img src="../../assets/Images/lingxing.png" />
        </div>
      </div>
      
    </div>
     <div class="zj_inside flexrow">
         <div class="pic wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      
        <div class="caseleft flexcc">
          <img src="../../assets/Images/940.png" />
        </div>
      </div>
      
    <div class="detailright wow bounceInleft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div class="line1 flextbc">
          <img src="../../assets/Images/ks_td1.png" />
        </div>
        <div class="line2 font14rem fontweight black">{{$t("2.对内监管、激励")}}</div>
        <div :class="$i18n.locale == 'CN'?'line3 font8rem black2':'line3EN font8rem black2'">
          {{$t("数据可视化与业务紧密相连，可以让员工轻松的了解到工作的进度，让领导分析其业务表现，同时管理者可以根据此来改变整体业务的方式和方向，帮助分析绩效以及预测未来的绩效。通过分析，能够掌握到企业的动态，做到趋利避害：")}}
        </div>
        <div :class="$i18n.locale == 'CN'?'line4 font14rem fontweight black':'line4EN font14rem fontweight black'">{{$t("（1）目标展示，鼓励业务人员完成目标")}}</div>
         <div :class="$i18n.locale == 'CN'?'line4 font14rem fontweight black':'line4EN font14rem fontweight black'">{{$t("（2）透明激励、横向对比竞争")}}</div>
          <div :class="$i18n.locale == 'CN'?'line4 font14rem fontweight black':'line4EN font14rem fontweight black'">{{$t("（3）分析复盘，根据实际完成情况，提高管理者决策正确性")}}</div>
       
      </div>
    
    </div>

     <div class="zj_inside flexrow">
      <div class="detail wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div class="line1 flextbc">
          <img src="../../assets/Images/ks_td1.png" />
        </div>
        <div class="line2 font14rem fontweight black">{{$t("3.对外宣传")}}</div>
        <div :class="$i18n.locale == 'CN'?'line3 font8rem black2':'line3EN font8rem black2'">
         {{$t("引入“面向对象”的场景构建方法，对不同的宣传对象在满足保密要求的前提下，实现宣传内容的精准投放，以发挥企业优势、提升客户满意度，有效推动企业品牌建设：")}}
        </div>
        <div :class="$i18n.locale == 'CN'?'line4 font14rem fontweight black':'line4EN font14rem fontweight black'">{{$t("（1）直观掌握企业运营状态，及时获取企业数据")}}</div>
         <div :class="$i18n.locale == 'CN'?'line4 font14rem fontweight black':'line4EN font14rem fontweight black'">{{$t("（2）有效利用数据资产，充分发挥数据价值")}}</div>
          <div :class="$i18n.locale == 'CN'?'line4 font14rem fontweight black':'line4EN font14rem fontweight black'">{{$t("（3）一体化的数字化展示窗口，及时、高效的进行全域信息的数字化展示")}}</div>
       
      </div>
      <div class="pic wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      
        <div class="case flexcc">
          <img src="../../assets/Images/1040.png" />
        </div>
      </div>
      
    </div>

    




  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_productIntroduce",
  data() {
    return {
        xlsPoints: [
        {
          index: 1,
          name: "BI报表与大屏一体化",
          detail:
            "集BI报表与大屏功能于一体，满足客户不同场景的可视化需求",
          icon: require("../../assets/Images/xls/xls_ld1.png"),
        },
        {
          index: 2,
          name: "移动端完美适配",
          detail:
            "布局自动适配移动端界面，随时随地查看分析可视化数据",
          icon: require("../../assets/Images/xls/xls_ld2.png"),
        },
        {
          index: 3,
          name: "数据产品化  让数据分析思路可沉淀 ",
          detail:
            "展现、分析、诊断、决策，从数据发现到数据决策，对特定业务数据进行全方位分析",
          icon: require("../../assets/Images/xls/xls_ld3.png"),
        },
        {
          index: 4,
          name: "PC端所有大屏配置功能，移动端可完美复用，双端联控",
          detail:
            "PC端所有大屏配置功能，均支持一键生成移动端报表，实现一次配置，多端查看。同时，大屏和移动端联动，可支持移动端远程控制大屏显示内容切换调整功能。",
          icon: require("../../assets/Images/xls/xls_ld4.png"),
        }
      ],
      logodata: [],
    };
  },
  watch: {},
  mounted() {
    new this.$wow.WOW({ live: true }).init();
  },
  methods: {},
};
</script>

<style scoped lang="scss">
 .cnname {
    width: 100%;
  }
  .nameline {
    width: 100%;
    .line {
      width: 6rem;
      height: 0.3rem;
      background-color: #2473e3;
      border-radius: 10px;
      margin-top: 1rem;
    }
  }
.zj_productIntroduce {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/Images/casebg1.png");
  background-position: 100% 0%;
  background-repeat: no-repeat;
  background-size: 100%;
  .zj_inside {
    width: 65%;
    height: 28rem;
    margin: auto;
          .detailnopic {
      width: 90%;
      height: 10rem;
      margin-top: 1%;
      
      .line1{
        
margin-bottom: 1rem;

      }
      .line2 {
        width: 100%;
        height: 2rem;
        img {
          width: 3%;
        }
      }
      .line3 {
        width: 100%;
        height:9rem;
        line-height: 2rem;
      }
      .line4 {
        width: 100%;
        height: 10%;
        line-height: 1.5rem;
        font-size: 1rem;
        color: rgb(36,115,227);
        margin-bottom: 1rem;
      }
      .line4EN {
        width: 150%;
        height: 10%;
        line-height: 1.5rem;
        font-size: 1rem;
        color: rgb(36,115,227);
        margin-bottom: 1rem;
        text-align: left;
      }
      .line5 {
        width: 100%;
        height: 5rem;
        line-height: 2rem;
        color: #999;
      }
    }
       .detailright {
      width: 45%;
      height: 10rem;
      margin-top: 1%;
      margin-left: 7%;
       .line1{
        
margin-bottom: 1rem;

      }
      .line2 {
        width: 100%;
        height: 2rem;
        img {
          width: 14%;
        }
      }
      .line3 {
        width: 100%;
        height:7rem;
        line-height: 1.6rem;
        font-size: 0.8rem;
      }
      .line3EN {
        width: 100%;
        height:7rem;
        line-height: 1.2rem;
        font-size: 0.8rem;
        margin-bottom:1.5rem
      }
      .line4 {
        width: 100%;
        height: 10%;
        line-height: 1.5rem;
        font-size: 0.9rem;
        color: rgb(36,115,227);
        margin-bottom: 1rem;
      }
      .line4EN {
        width: 150%;
        height: 10%;
        line-height: 1.5rem;
        font-size: 1rem;
        color: rgb(36,115,227);
        margin-bottom: 1rem;
        text-align: left;
      }
      .line5 {
        width: 100%;
        height: 5rem;
        line-height: 2rem;
        color: #999;
      }
    }
    .h20{
      width: 100%;
height: 7rem;

    }
       .h50{
      
height: 10rem;

    }
       .h10{
height: 1rem;

    }
    .detail {
      width: 40%;
      height: 10rem;
      margin-top: 1%;
      .line1{
        
margin-bottom: 1rem;

      }
      .line2 {
        width: 100%;
        height: 2rem;
        img {
          width: 14%;
        }
      }
      .line3 {
        width: 100%;
        height:7rem;
        line-height: 1.6rem;
        font-size: 0.8rem;
      }
      .line3EN {
        width: 100%;
        height:7rem;
        line-height: 1.2rem;
        font-size: 0.8rem;
        margin-bottom:1.5rem

      }
      .line4 {
        width: 100%;
        height: 10%;
        line-height: 1.5rem;
        font-size: 0.9rem;
        color: rgb(36,115,227);
        margin-bottom: 1rem;
      }
      .line4EN {
        width: 100%;
        height: 20%;
        line-height: 1.5rem;
        font-size: 1rem;
        color: rgb(36,115,227);
        margin-bottom: 1rem;
        text-align: left;
      }
      .line5 {
        width: 100%;
        height: 5rem;
        line-height: 2rem;
        color: #999;
      }
    }
    .pic {
      width: 60%;
      height: 100%;
      .title {
        width: 100%;
        height: 20%;
      }
      .case {
        width: 100%;
        height: 18rem;
        margin-left: 10%;
        margin-top: 12%;
        img {
          width: 100%
        }
      }
        .caseleft {
        width: 100%;
        height: 18rem;
        
        margin-top: 12%;
        img {
          width: 100%
        }
      }
    }
  }
}
.zj_xlsPoints {
  width: 100%;
  height: 100%;
 
  .cnname {
    width: 100%;
      }
  .nameline {
    width: 100%;
    margin-bottom: 1rem;
    .line {
      width: 8rem;
      height: 0.3rem;
      background-color: #2473e3;
      border-radius: 10px;
    }
  }
  .serveSlide {
    width: 100%;
    height: 80%;
    margin-top: 1%;
    .line{
      background-image: url("../../assets/Images/line.png");
        background-position: 100% 140%;
    background-repeat: no-repeat;
    background-size: 0.7% 70%;
    }
    .adv_part {
      width: 25%;
      height: 50%;
      float: left;
      .pic2 {
        width: 100%;
        //height: 30%;
        img{
          width: 13%;
        }
      }
      .name {
        margin-left: 15%;
        width: 70%;
        height: 20%;
        text-align: center;
        font-size: 0.7rem;
        font-weight: bold;
        margin-top:2rem;
      }
      .detail2 {
        
        width: 85%;
        height: 50%;
        line-height: 1.3rem;
        margin: 0 auto;
        text-align: center;
         margin-top: 5%;
          font-size:0.6rem;
      }
    }
  }
}
</style>
