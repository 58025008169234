<template>
  <!--零售数字化移动BI简介-->
  <div class="zj_productIntroduce">
    <div class="zj_inside flexrow">
      <div class="detail wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div class="line1 flextbc">
          <img src="../../assets/Images/ks_td1.png" />
        </div>
        <div :class="$i18n.locale == 'CN'?'line2 font14rem fontweight black':'line2EN font14rem fontweight black'">{{$t("1.丰富的数据可视化编辑工具")}}</div>
        <div :class="$i18n.locale == 'CN'?'line3 font8rem black2':'line3EN font8rem black2'">
          {{$t("通过丰富的组件库和模板库，以及智能化的辅助手段，极大的降低非专业开发人员的搭建门槛。")}}
        </div>
        <div :class="$i18n.locale == 'CN'?'line4 font14rem fontweight black':'line4EN font14rem fontweight black'">{{$t("可视化拖拽界面，强大的编辑功能")}}</div>
        <div class="line5 font8rem black2">
         {{$t("提供多种业务模块级的非图表组件，所见即所得式的配置方式，只需要通过拖拉拽即可创造出专业的可视化应用")}}
        </div>
      </div>
      <div class="pic wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      
        <div class="case flexcc">
          <img src="../../assets/Images/640.gif" />
        </div>
      </div>
      
    </div>
     <div class="zj_inside flexrow">
         <div class="pic wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      
        <div class="caseleft flexcc">
          <img src="../../assets/Images/640 (1).gif" />
        </div>
      </div>
      
      <div class="detailright wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      <div class="h20"></div>
        <div :class="$i18n.locale == 'CN'?'line4 font14rem fontweight black':'line4EN font14rem fontweight black'">{{$t("丰富的模板库")}}</div>
        <div class="line5 font8rem black2">
         {{$t("多种场景模版，简单修改即可快速投入使用。即便没有设计师，也可搭建专业大屏")}}
        </div>
      </div>
    
    </div>

     <div class="zj_inside flexrow">
      <div class="detail wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div class="h20"></div>
        <div :class="$i18n.locale == 'CN'?'line4 font14rem fontweight black':'line4EN font14rem fontweight black'">{{$t("丰富的组件库")}}</div>
        <div class="line5 font8rem black2">
         {{$t("提供多种业务模块级的非图表组件，所见即所得式的配置方式，只需要通过拖拉拽即可创造出专业的可视化应用")}}
        </div>
      </div>
      <div class="pic wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      
        <div class="case flexcc">
          <img src="../../assets/Images/640.gif" />
        </div>
      </div>
      
    </div>

     <div class="zj_inside flexrow">
         <div class="pic wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      
        <div class="caseleft flexcc">
          <img src="../../assets/Images/640 (3).gif" />
        </div>
      </div>
      
      <div class="detailright wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      <div class="h20"></div>
        <div :class="$i18n.locale == 'CN'?'line4 font14rem fontweight black':'line4EN font14rem fontweight black'">{{$t("支持自定义组件")}}</div>
        <div class="line5 font8rem black2">
         {{$t("提供开发者工具可以基于一定的组件规范，快速开发出自定义组件满足个性化需求。")}}


        </div>
        <div class="line5 font8rem black2">
         {{$t("自定义组件存放在用户自己的组件库中，可供用户自由复用。")}}


        </div>
      </div>
    
    </div>

 <div class="zj_inside flexrow">
      <div class="detail wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div class="line1 flextbc">
          <img src="../../assets/Images/ks_td2.png" />
        </div>
        <div :class="$i18n.locale == 'CN'?'line2 font14rem fontweight black':'line2EN font14rem fontweight black'">{{$t("2.专业的地理信息可视化")}}</div>
        <div :class="$i18n.locale == 'CN'?'line3 font8rem black2':'line3EN font8rem black2'">
          {{$t("通过组件开发工具、编辑器等模块，帮助专业可视化开发人员灵活的进行组件开发和交互配置。")}}
        </div>
        <div :class="$i18n.locale == 'CN'?'line4 font14rem fontweight black':'line4EN font14rem fontweight black'">{{$t("专业地理信息组件")}}</div>
        <div class="line5 font8rem black2">
         {{$t("支持绘制地理轨迹、地理飞线、热力分布、3D地球等效果，可以一站式的完成空间地理数据相关的可视分析。")}}
        </div>
      </div>
      <div class="pic wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      
        <div class="case flexcc">
          <img src="../../assets/Images/640 (4).gif" />
        </div>
      </div>
      
    </div>

  <div class="zj_inside flexrow">
         <div class="pic wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      
        <div class="caseleft flexcc">
          <img src="../../assets/Images/640 (5).gif" />
        </div>
      </div>
      
      <div class="detailright wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      <div class="h20"></div>
        <div :class="$i18n.locale == 'CN'?'line4 font14rem fontweight black':'line4EN font14rem fontweight black'">{{$t("低代码交互功能开发")}}</div>
        <div class="line5 font8rem black2">
        {{$t(" 通过蓝图编辑器来实现组件间的交互配置，复杂的交互逻辑可视化呈现，更易于维护。")}}
        </div>
      </div>
    
    </div>
    <div class="zj_inside flexrow">
      <div class="detailnopic wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div class="line1 flextbc">
          <img src="../../assets/Images/ks_td3.png" />
        </div>
        <div :class="$i18n.locale == 'CN'?'line2 font14rem fontweight black':'line2EN font14rem fontweight black'">{{$t("3.大屏与移动BI互为一体，共同联动控制——移动BI配置工具")}}</div>
        <div class="h10"></div>
        <div :class="$i18n.locale == 'CN'?'line3 font8rem black2':'line3EN font8rem black2'">
         {{$t("自助BI报表分析和制作可视化数据大屏的强大工具，通过拖拽图表组件可实现 5 分钟搭建数据可视化页面，组件丰富。通过可视化图表及强大的交互分析能力，为企业快速构建敏捷智能数据分析平台。")}}
          
        </div>




       <div class="zj_xlsPoints">
    <div class="cnname font16rem black flexcc wow bounceIn"
    data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">{{$t("优势")}}</div>
    <div class="serveSlide">
      <div class="adv_part wow bounceInUp" v-for="item in xlsPoints" :key="item.index" :class="item.index%4!=0?'line':'line22'"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div class="pic2 flexlrc">
          <img :src="item.icon" />
        </div>
        <div :class="$i18n.locale == 'CN'?'name flexcc font12rem black':'nameEN flexcc font12rem black'" >{{item.name}}</div>
        <div class="detail2 font8rem black2">{{item.detail}}</div>
      </div>
    </div>
  </div>





      </div>
   
    </div>

 <div class="zj_inside flexrow">
   
      <div class="detail wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
       <div class="h20" ></div>
        <div :class="$i18n.locale == 'CN'?'line4 font14rem fontweight black':'line4EN font14rem fontweight black'">{{$t("新一代敏捷BI 让决策不再等数据")}}</div>
        <div class="line5 font8rem black2">
        {{$t("丰富的功能和可视化图表模板，快速生成报表，满足企业数据分析和可视化展示需求")}}
        </div>
      </div>
      <div class="pic wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      
        <div class="case flexcc">
          <img src="../../assets/Images/newbi.png" />
        </div>
      </div>
      
    </div>

  <div class="zj_inside flexrow">
         <div class="pic wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      
        <div class="caseleft flexcc">
          <img src="../../assets/Images/newbii.png" />
        </div>
      </div>
      
      <div class="detailright wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      <div class="h20"></div>
        <div :class="$i18n.locale == 'CN'?'line4 font14rem fontweight black':'line4EN font14rem fontweight black'">{{$t("简单好用易上手 快速完成数据分析报告")}}</div>
        <div class="line5 font8rem black2">
         {{$t("产品使用门槛低，与数据源打通，拖拽点选、快速响应、即时生效、灵活调整，简单易上手")}}
        </div>
      </div>
    
    </div>
<div class="zj_inside flexrow">
   
      <div class="detail wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
       <div class="h20" ></div>
        <div :class="$i18n.locale == 'CN'?'line4 font14rem fontweight black':'line4EN font14rem fontweight black'">{{$t("极致美学设计、搭配高效的可视化技术")}}</div>
        <div class="line5 font8rem black2">
       {{$t(" 极致的数据美学，结合魔元术可视化技术，生动友好的呈现数据，同时提供丰富的报表模板，快速搭建设计师水准的数据报表")}}
        </div>
      </div>
      <div class="pic wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      
        <div class="case flexcc">
          <img src="../../assets/Images/newpeizhi.png" />
        </div>
      </div>
      
    </div>
 <div class="zj_inside flexrow">
         <div class="pic wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      
        <div class="caseleft flexcc">
          <img src="../../assets/Images/many.gif" />
        </div>
      </div>
      
      <div class="detailright wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      <div class="h20"></div>
        <div :class="$i18n.locale == 'CN'?'line4 font14rem fontweight black':'line4EN font14rem fontweight black'">{{$t("灵活多端，一次配置多端查看")}}</div>
        <div class="line5 font8rem black2">
         {{$t("集成微信或企业微信，手机、PC、大屏等多种设备上自适应展示，随时随地查看分析可视化数据")}}
        </div>
      </div>
    
    </div>




  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_productIntroduce",
  data() {
    return {
        xlsPoints: [
        {
          index: 1,
          name: this.$t("BI报表与大屏一体化"),
          detail:
           this.$t("集BI报表与大屏功能于一体，满足客户不同场景的可视化需求"),
          icon: require("../../assets/Images/xls/xls_ld1.png"),
        },
        {
          index: 2,
          name: this.$t("移动端完美适配"),
          detail:
            this.$t("布局自动适配移动端界面，随时随地查看分析可视化数据"),
          icon: require("../../assets/Images/xls/xls_ld2.png"),
        },
        {
          index: 3,
          name: this.$t("数据产品化  让数据分析思路可沉淀"),
          detail:
            this.$t("展现、分析、诊断、决策，从数据发现到数据决策，对特定业务数据进行全方位分析"),
          icon: require("../../assets/Images/xls/xls_ld3.png"),
        },
        {
          index: 4,
          name: this.$t("PC端所有大屏配置功能，移动端可完美复用，双端联控"),
          detail:
            this.$t("PC端所有大屏配置功能，均支持一键生成移动端报表，实现一次配置，多端查看。同时，大屏和移动端联动，可支持移动端远程控制大屏显示内容切换调整功能。"),
          icon: require("../../assets/Images/xls/xls_ld4.png"),
        }
      ],
      logodata: [],
    };
  },
  watch: {
    '$i18n.locale': function (newVal) {
        this.xlsPoints= [
        {
          index: 1,
          name: this.$t("BI报表与大屏一体化"),
          detail:
           this.$t("集BI报表与大屏功能于一体，满足客户不同场景的可视化需求"),
          icon: require("../../assets/Images/xls/xls_ld1.png"),
        },
        {
          index: 2,
          name: this.$t("移动端完美适配"),
          detail:
            this.$t("布局自动适配移动端界面，随时随地查看分析可视化数据"),
          icon: require("../../assets/Images/xls/xls_ld2.png"),
        },
        {
          index: 3,
          name: this.$t("数据产品化  让数据分析思路可沉淀"),
          detail:
            this.$t("展现、分析、诊断、决策，从数据发现到数据决策，对特定业务数据进行全方位分析"),
          icon: require("../../assets/Images/xls/xls_ld3.png"),
        },
        {
          index: 4,
          name: this.$t("PC端所有大屏配置功能，移动端可完美复用，双端联控"),
          detail:
            this.$t("PC端所有大屏配置功能，均支持一键生成移动端报表，实现一次配置，多端查看。同时，大屏和移动端联动，可支持移动端远程控制大屏显示内容切换调整功能。"),
          icon: require("../../assets/Images/xls/xls_ld4.png"),
        }
      ]
   }
  },
  mounted() {
    new this.$wow.WOW({ live: true }).init();
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.zj_productIntroduce {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/Images/casebg1.png");
  background-position: 100% 0%;
  background-repeat: no-repeat;
  background-size: 100%;
  .zj_inside {
    width: 65%;
    height: 28rem;
    margin: auto;
          .detailnopic {
      width: 90%;
      height: 10rem;
      margin-top: 1%;
      
      .line1{
        
margin-bottom: 1rem;

      }
      .line2 {
        width: 100%;
        height: 2rem;
        img {
          width: 14%;
        }
      }
      .line3 {
        width: 100%;
        height:6rem;
        line-height: 2rem;
      }
      .line4 {
        width: 100%;
        height: 10%;
        line-height: 1.5rem;
        font-size: 1rem;
        color: rgb(36,115,227);
        margin-bottom: 1rem;
      }
      .line4EN {
        width: 100%;
        height: 10%;
        line-height: 1.2rem;
        font-size: 1rem;
        color: rgb(36,115,227);
        margin-bottom: 1rem;
      }
      .line5 {
        width: 100%;
        height: 5rem;
        line-height: 2rem;
        color: #999;
      }
    }
       .detailright {
      width: 30%;
      height: 10rem;
      margin-top: 1%;
      margin-left: 7%;
      .line1{
        
margin-bottom: 1rem;

      }
      .line2 {
        width: 100%;
        height: 2rem;
        img {
          width: 14%;
        }
      }
      .line3 {
        width: 100%;
        height:6rem;
        line-height: 2rem;
      }
      .line3EN {
        width: 100%;
        height:6rem;
        line-height: 1.6rem;
      }
      .line4 {
        width: 100%;
        height: 10%;
        line-height: 1.5rem;
        font-size: 1rem;
        color: rgb(36,115,227);
        margin-bottom: 1rem;
      }
       .line4EN {
        width: 100%;
        height: 10%;
        line-height: 1.2rem;
        font-size: 1rem;
        color: rgb(36,115,227);
        margin-bottom: 1rem;
      }
      .line5 {
        width: 100%;
        height: 5rem;
        line-height: 2rem;
        color: #999;
      }
    }
    .h20{
      width: 100%;
height: 7rem;

    }
       .h50{
      
height: 10rem;

    }
       .h10{
height: 1rem;

    }
    .detail {
      width: 40%;
      height: 10rem;
      margin-top: 1%;
      .line1{
        
margin-bottom: 1rem;

      }
      .line2 {
        width: 100%;
        height: 2rem;
        img {
          width: 14%;
        }
      }
      .line2EN {
        width: 100%;
        height: -1rem;
        img {
          width: 14%;
        }
      }
      .line3 {
        width: 100%;
        height:5rem;
        line-height: 2rem;
      }
      .line3EN {
        width: 100%;
        height:5rem;
        line-height: 1.6rem;
      }
      .line4 {
        width: 100%;
        height: 10%;
        line-height: 1.5rem;
        font-size: 1rem;
        color: rgb(36,115,227);
        margin-bottom: 1rem;
      }
       .line4EN {
        width: 100%;
        height: 10%;
        line-height: 1.2rem;
        font-size: 1rem;
        color: rgb(36,115,227);
        margin-bottom: 1rem;
      }
      .line5 {
        width: 100%;
        height: 5rem;
        line-height: 2rem;
        color: #999;
      }
    }
    .pic {
      width: 60%;
      height: 100%;
      .title {
        width: 100%;
        height: 20%;
      }
      .case {
        width: 100%;
        height: 18rem;
        margin-left: 10%;
        margin-top: 12%;
        img {
          width: 100%
        }
      }
        .caseleft {
        width: 100%;
        height: 18rem;
        
        margin-top: 12%;
        img {
          width: 100%
        }
      }
    }
  }
}
.zj_xlsPoints {
  width: 100%;
  height: 100%;
 
  .cnname {
    width: 100%;
      }
  .nameline {
    width: 100%;
    margin-bottom: 1rem;
    .line {
      width: 8rem;
      height: 0.3rem;
      background-color: #2473e3;
      border-radius: 10px;
    }
  }
  .serveSlide {
    width: 100%;
    height: 80%;
    margin-top: 1%;
    .line{
      background-image: url("../../assets/Images/line.png");
        background-position: 100% 140%;
    background-repeat: no-repeat;
    background-size: 0.7% 70%;
    }
    .adv_part {
      width: 25%;
      height: 50%;
      float: left;
      .pic2 {
        width: 100%;
        //height: 30%;
        img{
          width: 13%;
        }
      }
      .name {
        margin-left: 15%;
        width: 70%;
        height: 20%;
        text-align: center;
        font-size: 0.7rem;
        font-weight: bold;
        margin-top:2rem;
      }
      .nameEN {
        margin-left: 15%;
        width: 77%;
        height: 20%;
        text-align: center;
        font-size: 0.7rem;
        font-weight: bold;
        margin-top:2rem;
        margin-bottom: 1.3rem;
      }
      .detail2 {
        
        width: 85%;
        height: 50%;
        line-height: 1.3rem;
        margin: 0 auto;
        text-align: center;
         margin-top: 5%;
          font-size:0.6rem;
      }
    }
  }
}
</style>
