<template>
  <!--数据驱动业务增长-->
  <div class="zj_businessGrowth">
    <div class="cnname flexcc font16rem black wow bounceIn"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">{{$t("魔数智屏的优势")}}</div>
    <div class="enname black font10rem flexcc wow bounceIn"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">Advantages Of Magic Number Smart Screen</div>
    <div class="oaad_out">
      <div class="oaad_in1 flexcc wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <img src="../../assets/Images/youshipic.png" />
      </div>
      <div class="oaad_pic wow bounceIn"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <img src="../../assets/Images/bi_pic2.png" />
      </div>
      <div class="oaad_in2 flexrow wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div class="oaad_left">
          <div class="oaad_ys flexrow flextbc marg1">
            <div class="pic flexcc">
              <img :src="businessGrowthData[0].icon" />
            </div>
            <div class="content">
              <div class="name font10rem black">
                {{ businessGrowthData[0].name }}
              </div>
              <div :class="$i18n.locale == 'CN'?'detail font8rem black2':'detailEN font8rem black2'">
                {{ businessGrowthData[0].value }}
              </div>
            </div>
          </div>
          <div class="oaad_ys flexrow flextbc marg2">
            <div class="pic flexcc">
              <img :src="businessGrowthData[1].icon" />
            </div>
            <div class="content">
              <div class="name font10rem black">
                {{ businessGrowthData[1].name }}
              </div>
              <div :class="$i18n.locale == 'CN'?'detail font8rem black2':'detailEN font8rem black2'">
                {{ businessGrowthData[1].value }}
              </div>
            </div>
          </div>
          <div class="oaad_ys flexrow flextbc marg5">
            <div class="pic flexcc">
              <img :src="businessGrowthData[4].icon" />
            </div>
            <div class="content">
              <div class="name font10rem black">
                {{ businessGrowthData[4].name }}
              </div>
              <div :class="$i18n.locale == 'CN'?'detail font8rem black2':'detailEN font8rem black2'">
                {{ businessGrowthData[4].value }}
              </div>
            </div>
          </div>
        </div>
        <div class="oaad_right">
          <div class="oaad_ys flexrow flextbc marg3">
            <div class="content">
              <div class="name font10rem black">
                {{ businessGrowthData[2].name }}
              </div>
              <div :class="$i18n.locale == 'CN'?'detail font8rem black2':'detailEN font8rem black2'">
                {{ businessGrowthData[2].value }}
              </div>
            </div>
            <div class="pic flexcc">
              <img :src="businessGrowthData[2].icon" />
            </div>
          </div>
          <div class="oaad_ys flexrow flextbc marg4">
            <div class="content">
              <div class="name font10rem black">
                {{ businessGrowthData[3].name }}
              </div>
              <div :class="$i18n.locale == 'CN'?'detail font8rem black2':'detailEN font8rem black2'">
                {{ businessGrowthData[3].value }}
              </div>
            </div>
            <div class="pic flexcc">
              <img :src="businessGrowthData[3].icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_businessGrowth",
  data() {
    return {
      businessGrowthData: [
        {
          index: 1,
          name: this.$t("更强的图表交互能力"),
          icon: require("../../assets/Images/bi_zz.png"),
          value: this.$t("颜色预警、高亮联动、图表最大化、图表任意联动等多项功能，提供更好的图表交互能力。"),
        },
        {
          index: 2,
          name: this.$t("更强的探索式分析能力"),
          icon: require("../../assets/Images/bi_zy.png"),
          value: this.$t("通过报表间智能钻取与多维动态分析，真正实现探索式分析，从问题到原因，通过鼠标即可搞定！"),
        },
        {
          index: 3,
          name: this.$t("丰富精美的行业模板"),
          icon: require("../../assets/Images/bi_zb.png"),
          value: this.$t("我们定制了几十种样式精美的行业数据模板，用户可以从中选择最适合自己的方案模板，简单修改即可使用。如果已有的模板无法满足你的需要，也可以联系我们做定制开发。"),
        },
        {
          index: 4,
          name: this.$t("强大的地理信息可视化"),
          icon: require("../../assets/Images/bi_kj.png"),
          value: this.$t("在地理信息可视化方面你可以创建世界地图、全国地图和全国344个区县的地图，可以完成多层级视图(世界级视图、国家视图、省级视图等等)的切换操作，包括视图下钻与返回，还具有海量点图、热力图、动态连线等（此特性为echarts能力）。"),
        },
         {
          index: 5,
          name: this.$t("灵活的适配和部署"),
          icon: require("../../assets/Images/bi_kj.png"),
          value: this.$t("数据展现方案以网页形式发布，适配chrome、Edge等主流浏览器，还可以使用本地应用程序进行内嵌；移动端可以集成到微信、企业微信、钉钉中；通过定制调整可以支持适配各种尺寸、各种不规则形状的屏幕。"),
        },
      ],
    };
  },
  watch: {
    '$i18n.locale': function (newVal) {
        this.businessGrowthData= [
        {
          index: 1,
          name: this.$t("更强的图表交互能力"),
          icon: require("../../assets/Images/bi_zz.png"),
          value: this.$t("颜色预警、高亮联动、图表最大化、图表任意联动等多项功能，提供更好的图表交互能力。"),
        },
        {
          index: 2,
          name: this.$t("更强的探索式分析能力"),
          icon: require("../../assets/Images/bi_zy.png"),
          value: this.$t("通过报表间智能钻取与多维动态分析，真正实现探索式分析，从问题到原因，通过鼠标即可搞定！"),
        },
        {
          index: 3,
          name: this.$t("丰富精美的行业模板"),
          icon: require("../../assets/Images/bi_zb.png"),
          value: this.$t("我们定制了几十种样式精美的行业数据模板，用户可以从中选择最适合自己的方案模板，简单修改即可使用。如果已有的模板无法满足你的需要，也可以联系我们做定制开发。"),
        },
        {
          index: 4,
          name: this.$t("强大的地理信息可视化"),
          icon: require("../../assets/Images/bi_kj.png"),
          value: this.$t("在地理信息可视化方面你可以创建世界地图、全国地图和全国344个区县的地图，可以完成多层级视图(世界级视图、国家视图、省级视图等等)的切换操作，包括视图下钻与返回，还具有海量点图、热力图、动态连线等（此特性为echarts能力）。"),
        },
         {
          index: 5,
          name: this.$t("灵活的适配和部署"),
          icon: require("../../assets/Images/bi_kj.png"),
          value: this.$t("数据展现方案以网页形式发布，适配chrome、Edge等主流浏览器，还可以使用本地应用程序进行内嵌；移动端可以集成到微信、企业微信、钉钉中；通过定制调整可以支持适配各种尺寸、各种不规则形状的屏幕。"),
        },
      ]
   }
  },
  mounted() {
    new this.$wow.WOW({ live: true }).init();
  },
  methods: {},
};
</script>

<style scoped lang="scss">

.zj_businessGrowth {
  width: 100%;
  height: 80%;
 
  @keyframes bus1 {
    0% {
      transform: translate(0, 0%);
      opacity: 1;
    }
    50% {
      transform: translate(0%, -50%);
      opacity: 1;
    }
    100% {
      transform: translate(0%, -0%);
      opacity: 1;
    }
  }
  .cnname {
    width: 100%;
    height: 10%;
  }
  .enname {
    width: 100%;
    height: 2rem;
  }
  .oaad_out {
    width: 100%;
    height: 90%;
    margin: 0 auto;
    margin-top: 2rem;
    .oaad_in1 {
      width: 100%;
      height: 90%;
     margin-left: 1%;
      position: relative;
      img {
        width: 40%;
      }
    }
    .oaad_pic {
      width: 10%;
      height: 10%;
      margin-left: 42%;
      margin-top: -19%;
      position: relative;
      animation: bus1 3s infinite linear;
            -webkit-animation: bus1 3s infinite linear;
      img {
        width: 40%;
      }
    }
    .marg1 {
      margin-left:35%;
      margin-top: 10%;
    }
    .marg2 {
      margin-left: 22%;
      margin-top: 25%;
    }
    .marg3 {
      margin-left: 10%;
      margin-top: 22%;
    }
    .marg4 {
      margin-left: 10%;
      margin-top: 40%;
    }
    .marg5 {
      margin-left: 27%;
      margin-top: 20%;
    }
    .oaad_in2 {
      width: 100%;
      height: 100%;
      position: relative;
      top: -58%;
      .oaad_left {
        width: 33%;
        height: 100%;
        text-align: right;
        .oaad_ys {
          width: 75%;
          // height: 33.3%;
          .pic {
            width: 30%;
            height: 100%;
          }
          .content {
            margin-left: 2%;
            //width: 70%;
            height: 100%;
            .name {
              font-size: 1REM;
              color: RGB(36,115,227);
              text-align: LEFT;
              width: 100%;
              height: 50%;
            }
            .detail {
              color: #666;
              margin-top: 1%;
              font-size: 0.8REM;
              text-align: LEFT;
              width: 100%;
              height: 50%;
              line-height: 1.5REM;
            }
            .detailEN {
              color: #666;
              margin-top: 1%;
              font-size: 0.8REM;
              text-align: LEFT;
              width: 100%;
              height: 50%;
              line-height: 1.2REM;
            }
          }
        }
      }
      .oaad_right {
        width: 33%;
        height: 100%;
        text-align: right;
        margin-left: 34%;
        .oaad_ys {
          width: 75%;
          // height: 33.3%;
          .pic {
            width: 30%;
            height: 100%;
          }
          .content {
             margin-RIGHT: 2%;
            //width: 70%;
            height: 100%;
            .name {
              font-size: 1REM;
              color: RGB(36,115,227);
              text-align: LEFT;
              width: 100%;
              height: 50%;
            }
            .detail {
               text-align: LEFT;
              color: #666;
              margin-top: 1%;
              font-size: 0.8REM;
             
              width: 100%;
              height: 50%;
              line-height: 1.5REM;
            }
            .detailEN {
               text-align: LEFT;
              color: #666;
              margin-top: 1%;
              font-size: 0.8REM;
             
              width: 100%;
              height: 50%;
              line-height: 1.2REM;
            }
          }
        }
      }
    }
  }
}
</style>
