<template>
  <!--pass产品优势-->
  <div class="zj_passAdvantages">
    <div
      class="cnname font16rem black wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1"
    >
      {{$t("魔数智屏——数据可视化配置工具")}}
    </div>
    <div
      class="nameline wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1"
    >
      <div class="line"></div>
    </div>
   <div :class="$i18n.locale == 'CN'?'line3 font8rem black2':'line3EN font8rem black2'">
         {{$t("作为数据可视化配置工具，可以让更多的人看到数据可视化的魅力，帮助非专业的工程师通过图形化的界面轻松搭建专业水准的可视化应用，满足用户会议展览、业务监控、风险预警、地理信息分析等多种业务的展示需求。")}}
        </div>
         <div :class="$i18n.locale == 'CN'?'line3 font8rem black2':'line3EN font8rem black2'">
         {{$t("魔元术根据“三最”原则——企业最关键、领导最关心、业务最贴近，定制了上百种样式精美的行业数据模板，用户可以从中选择最适合自己的方案模板，简单修改即可使用。以多层级的数据大屏、看板为载体，梳理数据流程、整合企业中的重要指标数据，形成管理的“闭环”，让数据在大屏、在企业流程中“转”起来！")}}
        </div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_passAdvantages",
  data() {
    return {
      productAdv: [
        {
          index: 1,
          name: this.$t("开发效率高"),
          detail:
            this.$t("比传统定制开发效率快10倍左右，可省去程序前端页面、后台逻辑编写、程序集成等环节"),
          icon: require("../../assets/Images/product/ks_cp1.png"),
        },
        {
          index: 2,
          name: this.$t("使用门槛低"),
          detail: this.$t("无需浪费专业开发人员成本，只需配置开发的实施顾问即可操作"),
          icon: require("../../assets/Images/product/ks_cp2.png"),
        },
        {
          index: 3,
          name: this.$t("满足客户个性化要求"),
          detail:
            this.$t("该工具可满足客户80%左右的需求，剩余20%个性化定制，充分满足客户业务流程的多样化要求"),
          icon: require("../../assets/Images/product/ks_cp3.png"),
        },
        {
          index: 4,
          name: this.$t("重塑性强"),
          detail: this.$t("功能搭建自由灵活，保证满足零售企业随着市场不断的变化而变化"),
          icon: require("../../assets/Images/product/ks_cp4.png"),
        },
      ],
    };
  },
  watch: {
    '$i18n.locale': function (newVal) {
        this.productAdv= [
        {
          index: 1,
          name: this.$t("开发效率高"),
          detail:
            this.$t("比传统定制开发效率快10倍左右，可省去程序前端页面、后台逻辑编写、程序集成等环节"),
          icon: require("../../assets/Images/product/ks_cp1.png"),
        },
        {
          index: 2,
          name: this.$t("使用门槛低"),
          detail: this.$t("无需浪费专业开发人员成本，只需配置开发的实施顾问即可操作"),
          icon: require("../../assets/Images/product/ks_cp2.png"),
        },
        {
          index: 3,
          name: this.$t("满足客户个性化要求"),
          detail:
            this.$t("该工具可满足客户80%左右的需求，剩余20%个性化定制，充分满足客户业务流程的多样化要求"),
          icon: require("../../assets/Images/product/ks_cp3.png"),
        },
        {
          index: 4,
          name: this.$t("重塑性强"),
          detail: this.$t("功能搭建自由灵活，保证满足零售企业随着市场不断的变化而变化"),
          icon: require("../../assets/Images/product/ks_cp4.png"),
        },
      ]
   }
  },
  mounted() {
    new this.$wow.WOW({ live: true }).init();
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.zj_passAdvantages {
  width: 80%;
  height: 60%;
  margin-left: 10%;
  margin-top: 3rem;
  .cnname {
    width: 100%;
    margin-bottom: 1rem;
  }
  .nameline {
    width: 100%;
    margin-bottom: 1rem;
    .line {
      width: 8rem;
      height: 0.3rem;
      background-color: #2473e3;
      border-radius: 10px;
    }
  }
  .line3 {
        margin-top: 2%;
        width: 100%;
        height: 4%;
        line-height: 2.5rem;
        font-size: 0.75rem;
      }
  .line3EN {
        margin-top: 2%;
        width: 100%;
        height: 4%;
        line-height: 3.5rem;
        font-size: 0.75rem;
      }
  .serveSlide {
    width: 100%;
    height: 60%;
    margin-top: 6%;
    .adv_part {
      width: 18%;
      height: 100%;
      .pic {
        width: 100%;
        //height: 30%;
        img {
          width: 100%;
        }
      }
      .name {
        width: 100%;
        height: 20%;
        text-align: center;
      }
      .detail {
        width: 80%;
        height: 50%;
        line-height: 1.5rem;
        margin: 0 auto;
        text-align: center;
      }
    }
  }
}
</style>
