<template>
  <!--PASS快速开发平台工具介绍-->
  <div class="zj_passIntroduce">
    <div class="zj_inside flexrow">
      <div class="detail wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
   
       <div class="line2 font14rem fontweight black">{{$t("魔数智屏")}}</div>
        <div :class="$i18n.locale == 'CN'?'line3 font8rem black2':'line3EN font8rem black2'">
         {{$t("自2018年7月成立起，魔元术便开始对数据可视化大屏产品——魔数智屏做深入的研发，经过安踏集团、波司登集团、太平鸟集团等10多个大型客户的沉淀，以及一些中小型企业的打磨，结合魔元术研发团队十六年以上的行业信息化研发经验，千磨百炼，打造出行业适用性强、低代码敏捷化开发、拖拽式、多端（PC端、大屏端、移动端）互联控制、科技感十足的魔元术数据可视化大屏产品——魔数智屏。")}}
        </div>
         <div :class="$i18n.locale == 'CN'?'line3 font8rem black2':'line3EN font8rem black2'">
         {{$t("魔数智屏与行业需求深度结合，围绕核心业务系统进行统一融合，按需调用，帮助运维人员提高运维监控和故障处置的效率。系统提供数据可视化和三维全景可视化服务，为用户提供一站式展示汇报方案，帮助汇报者更加直观和便捷的展示汇报，帮助决策者更好的发掘数据背后的规律，提高决策效率与能力。")}}
        </div>
      </div>
      <div class="pic wow bounceInRight">
        <div class="case flexcc">
          <img src="../../assets/Images/product/ks_pic.gif" />
        </div>
        <div class="case1 flexcc">
          <img src="../../assets/Images/ks_sd.png" />
        </div>
        <div class="case2 flexcc">
          <img src="../../assets/Images/ks_zhuan.png" />
        </div>
        <div class="case3"></div>
        <div class="case4"></div>
        <div class="case5"></div>
        <div class="case6"></div>
        <div class="case7"></div>
        <div class="case8"></div>
      </div>
    </div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_passIntroduce",
  data() {
    return {
      logodata: [],
    };
  },
  watch: {},
  mounted() {
    new this.$wow.WOW({ live: true }).init();
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.zj_passIntroduce {
  width: 100%;
  height: 100%;
  .zj_inside {
    width: 65%;
    height: 100%;
    margin: auto;
    .detail {
      width: 40%;
      height: 95%;
      margin-top: 1%;
      .line1,
      .line2 {
        margin-top: 5%;
        width: 100%;
        height: 10%;
        img {
          width: 8%;
        }
      }
      .line3 {
        
        width: 100%;
        height: 52%;
        line-height: 2.5rem;
        font-size: 0.75rem;
      }
      .line3EN {
        
        width: 100%;
        height: 52%;
        line-height: 1.2rem;
        font-size: 0.75rem;
      }
    }
    .pic {
      width: 60%;
      height: 100%;
      .case {
        width: 100%;
        height: 100%;
        img {
          width: 80%;
        }
      }
      .case1 {
        width: 10%;
        height: 10%;
        position: relative;
        margin-left: 54%;
        margin-top: -49%;
        animation: pass1 3s infinite linear;
        -webkit-animation: pass1 3s infinite linear;
        img {
          width: 10%;
        }
      }
      .case2 {
        width: 10%;
        height: 10%;
        position: relative;
        margin-left: 26.5%;
        margin-top: 5.5%;
        animation: pass2 3s infinite linear;
        -webkit-animation: pass2 3s infinite linear;
        img {
          width: 30%;
          transform: skew(-50deg, 35deg);
        }
      }
      .case3{
        width: 0.4rem;
        height: 0.4rem;
        position: relative;
            margin-left: 58%;
       margin-top: -22%;
        border-radius: 100px;
        background-color: rgb(255, 169, 20);
        animation: pass3 3s infinite linear;
        -webkit-animation: pass3 3s infinite linear;
      }
      .case4{
        width: 0.4rem;
        height: 0.4rem;
        position: relative;
            margin-left: 58%;
       margin-top: -3%;
        border-radius: 100px;
        background-color: rgba(255, 169, 20,0.5);
        animation: pass4 3s infinite linear;
        -webkit-animation: pass4 3s infinite linear;
      }
      .case5{
        width: 0.4rem;
        height: 0.4rem;
        position: relative;
            margin-left: 58%;
       margin-top: -3%;
        border-radius: 100px;
        background-color: rgba(255, 169, 20,0.2);
        animation: pass5 3s infinite linear;
        -webkit-animation: pass5 3s infinite linear;
      }
      .case6{
        width: 0.4rem;
        height: 0.4rem;
        position: relative;
            margin-left: 61%;
       margin-top: 5%;
        border-radius: 100px;
        background-color:rgb(255, 61, 114,0.2);
        animation: pass5 3s infinite linear;
        -webkit-animation: pass5 3s infinite linear;
      }
      .case7{
        width: 0.4rem;
        height: 0.4rem;
        position: relative;
            margin-left: 61%;
       margin-top: -3%;
        border-radius: 100px;
        background-color: rgba(255, 61, 114,0.5);
        animation: pass4 3s infinite linear;
        -webkit-animation: pass4 3s infinite linear;
      }
      .case8{
        width: 0.4rem;
        height: 0.4rem;
        position: relative;
            margin-left: 61%;
       margin-top: -3%;
        border-radius: 100px;
        background-color: rgba(255, 61, 114,1);
        animation: pass3 3s infinite linear;
        -webkit-animation: pass3 3s infinite linear;
      }
    }
  }
  
  @keyframes pass5 {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes pass4 {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
   @keyframes pass3 {
    0% {
      opacity: 1;
    }
    25% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes pass2 {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes pass1 {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>
