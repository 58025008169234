import { render, staticRenderFns } from "./yingyong.vue?vue&type=template&id=670a2908&scoped=true&"
import script from "./yingyong.vue?vue&type=script&lang=js&"
export * from "./yingyong.vue?vue&type=script&lang=js&"
import style0 from "./yingyong.vue?vue&type=style&index=0&id=670a2908&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "670a2908",
  null
  
)

export default component.exports