<template>
  <!--零售数字化移动BI简介-->
  <div class="zj_productIntroduce">
     <div
      class="cnname flexcc font16rem black wow bounceIn"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1"
    >
    {{$t("应用行业案例")}}
    </div>
    <div
      class="nameline flexcc wow bounceIn"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1"
    >
      <div class="line"></div>
    </div>



    <div class="zj_inside flexrow">
      <div class="detail wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div class="line1 flextbc">
          <img src="../../assets/Images/brand/bsd.png" />
        </div>
        <div class="line2 font14rem fontweight black">{{$t("客户案例一：波司登集团")}}</div>
        <div :class="$i18n.locale == 'CN'?'line3 font8rem black2':'line3EN font8rem black2'">
          {{$t("波司登集团利用魔数智屏，基于自有的信息化系统，打造出了数据中台监控、供应链监控、产品业态分析、定制业务监控、O2O业务销售监控、车间实时监控、工单处理监控等。")}}
        </div>
         <div class="line4 font14rem fontweight black">{{$t("波司登店态数据中台大屏")}}</div>
        <div :class="$i18n.locale == 'CN'?'line5 font8rem black2':'line5EN font8rem black2'">
        {{$t("同一个大屏在不同店态的旗舰店的显示屏上，展示各自的营销动态，辅助店员及店长实时查看货品信息及销售状况，更具直观性及实时性。")}}
        </div>
        
        
      </div>
      <div class="pic wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      
        <div class="case flexcc">
          <img src="../../assets/Images/1140.png" />
        </div>
      </div>
      
    </div>
     <div class="zj_inside flexrow">
         <div class="pic wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      
        <div class="caseleft flexcc">
          <img src="../../assets/Images/0010.png" />
        </div>
      </div>
      
      <div class="detailright wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      <div class="h20"></div>
        <div class="line4 font14rem fontweight black">{{$t("波司登数据中台大屏")}}</div>
        <div :class="$i18n.locale == 'CN'?'line5 font8rem black2':'line5EN font8rem black2'">
        {{$t("主要用于管理层查询集团总体数据情况，支持多维度多角色数据查询，为管理层做决策起到数据支持性作用。")}}
        </div>
      </div>
    
    </div>

     <div class="zj_inside flexrow">
      <div class="detail wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div class="h20"></div>
        <div class="line4 font14rem fontweight black">{{$t("波司登O2O业务业务监控看板")}}</div>
        <div :class="$i18n.locale == 'CN'?'line5 font8rem black2':'line5EN font8rem black2'">
         {{$t("整体通过数据与地图的集合从不同的展示视角为用户展示不同区域的数据情况，数据动态展示与地图紧密结合，实现了对O2O业务全方位实时监控，O2O业务进度一目了然，帮助业务部门实时跟进，促进用户对企业的决策与效率。")}}
        </div>
         <div :class="$i18n.locale == 'CN'?'line55 font8rem black2':'line55EN font8rem black2'">
        {{$t("注：以上所有数据均为模拟数据")}}
        </div>
      </div>
      <div class="pic wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      
        <div class="case flexcc">
          <img src="../../assets/Images/0011.png" />
        </div>
      </div>
      
    </div>


 <div class="zj_inside flexrow">
    <div class="pic wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      
        <div class="caseleft flexcc">
          <img src="../../assets/Images/1140.png" />
        </div>
      </div>
     
      <div class="detailright wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
     
        <div class="line1 flextbc">
          <img src="../../assets/Images/brand/peacebird.png" />
        </div>
        <div :class="$i18n.locale == 'CN'?'line2 font14rem fontweight black':'line2EN font14rem fontweight black'">{{$t("客户案例二：太平鸟集团")}}</div>
        <div :class="$i18n.locale == 'CN'?'line33 font8rem black2':'line33EN font8rem black2'">
          {{$t("太平鸟2020年起使用魔数智屏产品，在企业形象墙上展示实时销售情况。大屏中对外销售数据经过脱敏处理，在有限的空间中表达庞大的信息量，精炼数据的同时保证数据的完整性，完美契合用户的菱形巨屏。当外部客户参观、领导视察时，菱形大屏的震撼效果彰显了公司的实力。")}}
        </div>
        
        <div :class="$i18n.locale == 'CN'?'line55 font8rem black2':'line55EN font8rem black2'">
        {{$t("注：所有数据均为模拟数据")}}
        </div>
        
        
      </div>
     
      
    </div>
  <div class="zj_inside flexrow">
      <div class="detail wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div class="line1 flextbc">
          <img src="../../assets/Images/brand/anta.png" />
        </div>
        <div class="line2 font14rem fontweight black">{{$t("客户案例三：安踏集团")}}</div>
        <div :class="$i18n.locale == 'CN'?'line3 font8rem black2':'line3EN font8rem black2'" >
          {{$t("2019年起服务于安踏新零售大数据的可视化系列页面，基于魔元术自有新零售数据中台产品，将安踏的销售数据、会员数据、区域数据等进行监控查看。同时适配PC端操作习惯，提供用户日、周、月、年等条件筛选，完美集成展示大屏和PC看板各自优势，全方位、多层次地展示零售数据，有效提高了对数据管理的宏观掌控、实时监控，为安踏的后续性能、数据分析打好了良好的基础。")}}
        </div>
           <div :class="$i18n.locale == 'CN'?'line55 font8rem black2':'line55EN font8rem black2'">
        {{$t("注：以上所有数据均为模拟数据")}}
        </div>
        
        
      </div>
      <div class="pic wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      
        <div class="case flexcc">
          <img src="../../assets/Images/1210.png" />
        </div>
      </div>
      
    </div>

    
 <div class="zj_inside flexrow">
    <div class="pic wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      
        <div class="caseleft flexcc">
          <img src="../../assets/Images/1234.gif" />
        </div>
      </div>
     
      <div class="detailright wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
     
        <div class="line1 flextbc">
          <img src="../../assets/Images/brand/suunto.png" />
        </div>
        <div :class="$i18n.locale == 'CN'?'line2 font14rem fontweight black':'line2EN font14rem fontweight black'">{{$t("客户案例四：SUNTOP（制造业）")}}</div>
        <div :class="$i18n.locale == 'CN'?'line33 font8rem black2':'line33EN font8rem black2'">
          {{$t("SUNTOP使用魔数智屏，根据自身硬件设备的传感器和监控，将数据汇总到魔数智屏后端，利用多屏轮播功能，直观的将总览信息、设备硬件信息、客流监控信息等数据整合在一起，还能顾及到数据的实时性，助力制造型企业“数字化转型”，提升管理体验。")}}
        </div>
        
        <div :class="$i18n.locale == 'CN'?'line55 font8rem black2':'line55EN font8rem black2'">
        {{$t("注：所有数据均为模拟数据")}}
        </div>
        
        
      </div>
     
      
    </div>




  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_productIntroduce",
  data() {
    return {
        xlsPoints: [
        {
          index: 1,
          name: "BI报表与大屏一体化",
          detail:
            "集BI报表与大屏功能于一体，满足客户不同场景的可视化需求",
          icon: require("../../assets/Images/xls/xls_ld1.png"),
        },
        {
          index: 2,
          name: "移动端完美适配",
          detail:
            "布局自动适配移动端界面，随时随地查看分析可视化数据",
          icon: require("../../assets/Images/xls/xls_ld2.png"),
        },
        {
          index: 3,
          name: "数据产品化  让数据分析思路可沉淀 ",
          detail:
            "展现、分析、诊断、决策，从数据发现到数据决策，对特定业务数据进行全方位分析",
          icon: require("../../assets/Images/xls/xls_ld3.png"),
        },
        {
          index: 4,
          name: "PC端所有大屏配置功能，移动端可完美复用，双端联控",
          detail:
            "PC端所有大屏配置功能，均支持一键生成移动端报表，实现一次配置，多端查看。同时，大屏和移动端联动，可支持移动端远程控制大屏显示内容切换调整功能。",
          icon: require("../../assets/Images/xls/xls_ld4.png"),
        }
      ],
      logodata: [],
    };
  },
  watch: {},
  mounted() {
    new this.$wow.WOW({ live: true }).init();
  },
  methods: {},
};
</script>

<style scoped lang="scss">
 .nameline {
    width: 100%;
    .line {
      width: 6rem;
      height: 0.3rem;
      background-color: #2473e3;
      border-radius: 10px;
      margin-top: 1rem;
    }
  }
.zj_productIntroduce {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/Images/casebg1.png");
  background-position: 100% 0%;
  background-repeat: no-repeat;
  background-size: 100%;
  .zj_inside {
    width: 65%;
    height: 28rem;
    margin: auto;
          .detailnopic {
      width: 90%;
      height: 10rem;
      margin-top: 1%;
      
      .line1{
        
margin-bottom: 1rem;

      }
      .line2 {
        width: 100%;
        height: 2rem;
        text-align: left;
        img {
          width: 14%;
        }
      }
      .line3 {
        width: 100%;
        height:8rem;
        line-height: 2rem;
      }
      .line3EN {
        width: 100%;
        height:8rem;
        line-height: 1.2rem;
      }
      .line4 {
        width: 100%;
        height: 10%;
        line-height: 1.5rem;
        font-size: 1rem;
        color: rgb(36,115,227);
        margin-bottom: 1rem;
      }
      .line5 {
        width: 100%;
        height: 5rem;
        line-height: 2rem;
        color: #999;
      }
      .line5EN {
        width: 100%;
        height: 5rem;
        line-height: 1.2rem;
        color: #999;
      }
    }
       .detailright {
      width: 40%;
      height: 10rem;
      margin-top: 1%;
      margin-left: 7%;
      .line1{
        margin-top: 2rem;
margin-bottom: 1rem;

      }
      .line2 {
        width: 100%;
        height: 2rem;
        text-align: left;
        img {
          width: 14%;
        }
      }
      .line2EN {
        width: 130%;
        height: 2rem;
        text-align: left;
        img {
          width: 14%;
        }
      }
      .line3 {
        width: 100%;
        height:6rem;
        line-height: 2rem;
      }
       .line3EN {
        width: 100%;
        height:8rem;
        line-height: 1.2rem;
      }
      .line33 {
        width: 100%;
        height:10rem;
        line-height: 2rem;
      }
      .line33EN {
        width: 100%;
        height:10rem;
        line-height: 1.2rem;
      }
      .line4 {
        width: 100%;
        height: 10%;
        line-height: 1.5rem;
        font-size: 1rem;
        color: rgb(36,115,227);
        margin-bottom: 1rem;
      }
      .line5 {
        width: 100%;
        height: 5rem;
        line-height: 2rem;
        color: #999;
      }
        .line5EN {
        width: 100%;
        height:5rem;
        line-height: 1.2rem;
        color: #999;
      }
       .line55 {
        width: 100%;
        height: 5rem;
        line-height: 2rem;
        color: rgbA(36,115,227,0.7);
        font-size:0.7rem;
      }
       .line55EN {
        width: 100%;
        height: 5rem;
        line-height: 5rem;
        color: rgbA(36,115,227,0.7);
        font-size:0.7rem;
      }
    }
    .h20{
     
height: 7rem;

    }
     .h10{
    width:100%; 
height: 4rem;

    }
       .h50{
      
height: 10rem;

    }
       .h10{
height: 1rem;

    }
    .detail {
      width: 40%;
      height: 10rem;
      margin-top: 1%;
      .line1{
        margin-top: 2rem;
margin-bottom: 1rem;

      }
       .line55 {
        width: 100%;
        height: 5rem;
        line-height: 2rem;
        color: rgbA(36,115,227,0.7);
        font-size:0.7rem;
        margin-top: 3rem;
      }
       .line55EN {
        width: 100%;
        height: 5rem;
        line-height: 10rem;
        color: rgbA(36,115,227,0.7);
        font-size:0.7rem;
        margin-top: 3rem;
      }
       .line555 {
        width: 100%;
        height: 5rem;
        line-height: 2rem;
        color: rgbA(36,115,227,0.7);
        font-size:0.7rem;
        margin-top: 6rem;
      }
      .line2 {
        width: 100%;
        height: 2rem;
        text-align: left;
        img {
          width: 14%;
        }
      }
      .line3 {
        width: 100%;
        height:7rem;
        line-height: 2rem;
      }
       .line3EN {
        width: 100%;
        height:8rem;
        line-height: 1.2rem;
      }
      .line4 {
        width: 100%;
        height: 10%;
        line-height: 1.5rem;
        font-size: 1rem;
        color: rgb(36,115,227);
        margin-bottom: 1rem;
      }
      .line5 {
        width: 100%;
        height: 5rem;
        line-height: 2rem;
        color: #999;
      }
        .line5EN {
        width: 100%;
        height: 3rem;
        line-height: 1.2rem;
        color: #999;
      }
    }
    .pic {
      width: 60%;
      height: 100%;
      .title {
        width: 100%;
        height: 20%;
      }
      .case {
        width: 100%;
        height: 18rem;
        margin-left: 10%;
        margin-top: 12%;
        img {
          width: 100%
        }
      }
        .caseleft {
        width: 100%;
        height: 18rem;
        
        margin-top: 12%;
        img {
          width: 100%
        }
      }
    }
  }
}
.zj_xlsPoints {
  width: 100%;
  height: 100%;
 
  .cnname {
    width: 100%;
      }
  .nameline {
    width: 100%;
    margin-bottom: 1rem;
    .line {
      width: 8rem;
      height: 0.3rem;
      background-color: #2473e3;
      border-radius: 10px;
    }
  }
  .serveSlide {
    width: 100%;
    height: 80%;
    margin-top: 1%;
    .line{
      background-image: url("../../assets/Images/line.png");
        background-position: 100% 140%;
    background-repeat: no-repeat;
    background-size: 0.7% 70%;
    }
    .adv_part {
      width: 25%;
      height: 50%;
      float: left;
      .pic2 {
        width: 100%;
        //height: 30%;
        img{
          width: 13%;
        }
      }
      .name {
        margin-left: 15%;
        width: 70%;
        height: 20%;
        text-align: center;
        font-size: 0.7rem;
        font-weight: bold;
        margin-top:2rem;
      }
      .detail2 {
        
        width: 85%;
        height: 50%;
        line-height: 1.3rem;
        margin: 0 auto;
        text-align: center;
         margin-top: 5%;
          font-size:0.6rem;
      }
    }
  }
}
</style>
