<template>
  <!--丰富的数据分析方法-->
  <div class="zj_analyticalMethod">
    <div
      class="cnname flexcc font16rem black wow bounceIn"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1"
    >
     {{$t("产品特征")}}
    </div>
    <div
      class="nameline flexcc wow bounceIn"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1"
    >
      <div class="line"></div>
    </div>
    <div class="serveSlide">
      <div
        class="adv_part wow bounceInUp"
        v-for="item in productAdv"
        :key="item.index"
        :class="item.index % 3 != 0 ? 'line' : 'line2'"
        data-wow-duration="2s"
        data-wow-delay="0s"
        data-wow-offset="10"
        data-wow-iteration="1"
      >
        <div class="pic flexlrc">
          <img :src="item.icon" />
        </div>
        <div class="name flexcc font12rem black">{{ item.name }}</div>
        <div class="detail font8rem black2">{{ item.detail }}</div>
      
      </div>

    </div>
    <div  style="display:flex">
      <div
        class="adv_part wow bounceInUp"
        v-for="item in pic"
        :key="item.index"
        :class="item.index % 3 != 0 ? 'line' : 'line2'"
        data-wow-duration="2s"
        data-wow-delay="0s"
        data-wow-offset="10"
        data-wow-iteration="1"
      >
      
    <div class="piczhanshi">
       <div class="picflex">
     <img style="width:100%"  :src="item.icon" />
      </div>
    <div class="title01">{{ item.name }}</div>
    </div>
   
    </div>
    </div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_analyticalMethod",
  data() {
    return {
      productAdv: [
        {
          index: 1,
          name: this.$t("专业级大数据可视化"),
          detail: this.$t("专精于地理信息与业务数据融合的可视化，提供丰富的行业模版和组件，支持自定义组件"),
          icon: require("../../assets/Images/bi_sj1.png"),
        },
        {
          index: 2,
          name: this.$t("实时高效的数据"),
          detail: this.$t("支持从静态数据、CSV 文件、公网数据库，腾讯云数据库和 API 等多种数据源接入数据，通过配置可以动态实时更新"),
          icon: require("../../assets/Images/bi_sj2.png"),
        },
        {
          index: 3,
          name: this.$t("拖拽式设计，在线自由布局设计"),
          detail: this.$t("拖拽即可完成样式和数据配置，无需编程就能轻松搭建数据大屏"),
          icon: require("../../assets/Images/bi_sj3.png"),
        },
        {
          index: 4,
          name: this.$t("炫酷的3D展现与动画特效"),
          detail: this.$t("具有极强的视觉体验和冲击力，提供丰富3D图表、轮播动画、自动翻页、表格滚动等动画特效，通过简单拖拽方式即可实现3D动画、自动播放等多种特效，科技感十足，让大屏展示效果更具炫酷性。"),
          icon: require("../../assets/Images/bi_sj4.png"),
        },
        {
          index: 5,
          name: this.$t("移动端完美适配"),
          detail: this.$t("布局自动适配移动端界面，随时随地查看分析可视化数据"),
          icon: require("../../assets/Images/bi_sj5.png"),
        },
        {
          index: 6,
          name: this.$t("灵活部署和推送"),
          detail: this.$t("适配非常规拼接大屏，支持自定义推送，支持本地部署"),
          icon: require("../../assets/Images/bi_sj6.png"),
        },
      ],
      pic: [
        {
          index: 1,
          name: this.$t("案例一"),
         
          icon: require("../../assets/Images/3D1  GIF.gif"),
        },
        {
          index: 2,
          name: this.$t("案例二"),
         
          icon: require("../../assets/Images/anlitwo.gif"),
        },
        {
          index: 3,
          name: this.$t("案例三"),
         
          icon: require("../../assets/Images/anlithree.gif"),
        }
      ],
    };
  },
  watch: {
    '$i18n.locale': function (newVal) {
        this.productAdv= [
        {
          index: 1,
          name: this.$t("专业级大数据可视化"),
          detail: this.$t("专精于地理信息与业务数据融合的可视化，提供丰富的行业模版和组件，支持自定义组件"),
          icon: require("../../assets/Images/bi_sj1.png"),
        },
        {
          index: 2,
          name: this.$t("实时高效的数据"),
          detail: this.$t("支持从静态数据、CSV 文件、公网数据库，腾讯云数据库和 API 等多种数据源接入数据，通过配置可以动态实时更新"),
          icon: require("../../assets/Images/bi_sj2.png"),
        },
        {
          index: 3,
          name: this.$t("拖拽式设计，在线自由布局设计"),
          detail: this.$t("拖拽即可完成样式和数据配置，无需编程就能轻松搭建数据大屏"),
          icon: require("../../assets/Images/bi_sj3.png"),
        },
        {
          index: 4,
          name: this.$t("炫酷的3D展现与动画特效"),
          detail: this.$t("具有极强的视觉体验和冲击力，提供丰富3D图表、轮播动画、自动翻页、表格滚动等动画特效，通过简单拖拽方式即可实现3D动画、自动播放等多种特效，科技感十足，让大屏展示效果更具炫酷性。"),
          icon: require("../../assets/Images/bi_sj4.png"),
        },
        {
          index: 5,
          name: this.$t("移动端完美适配"),
          detail: this.$t("布局自动适配移动端界面，随时随地查看分析可视化数据"),
          icon: require("../../assets/Images/bi_sj5.png"),
        },
        {
          index: 6,
          name: this.$t("灵活部署和推送"),
          detail: this.$t("适配非常规拼接大屏，支持自定义推送，支持本地部署"),
          icon: require("../../assets/Images/bi_sj6.png"),
        },
      ]
      this.pic = [
        {
          index: 1,
          name: this.$t("案例一"),
         
          icon: require("../../assets/Images/3D1  GIF.gif"),
        },
        {
          index: 2,
          name: this.$t("案例二"),
         
          icon: require("../../assets/Images/anlitwo.gif"),
        },
        {
          index: 3,
          name: this.$t("案例三"),
         
          icon: require("../../assets/Images/anlithree.gif"),
        }
      ]
   }
  },
  mounted() {
    new this.$wow.WOW({ live: true }).init();
  },
  methods: {},
};
</script>

<style scoped lang="scss">

.zj_analyticalMethod {
  
  width: 80%;
  height: 100%;
  margin-left: 10%;
  margin-top: 3rem;
  .piczhanshi{
    margin-top: 10%;
width: 95%;
margin-left: 4%;

  }
  .pic0003{
background-image: url("../../assets/Images/3D1  GIF.gif") ;

 background-repeat: no-repeat;
    background-size: 32%;
     width: 100%;
    height: 19rem;
    margin-top: 6%;
    
}
.picflex{
  width:100%;
 
}
.title01{
width: 100%;
text-align: center;
font-size: 0.8rem;
color: #2473e3;

}
  .cnname {
    width: 100%;
  }
  .nameline {
    width: 100%;
    .line {
      width: 6rem;
      height: 0.3rem;
      background-color: #2473e3;
      border-radius: 10px;
      margin-top: 1rem;
    }
  }
  .serveSlide {
    width: 100%;
    height: 20rem;
    margin-top: 6%;
    .line {
      background-image: url("../../assets/Images/line.png");
      background-position: 100% 38%;
      background-repeat: no-repeat;
      background-size: 0.5% 34%;
    }
    .adv_part {
      width: 33%;
      height: 50%;
      float: left;
      .pic {
        width: 100%;
        //height: 30%;
        img {
          width: 10%;
        }
      }
      
      .name {
        width: 100%;
        height: 20%;
        text-align: center;
        font-size: 1rem;
      }
      .detail {
        width: 60%;
        height: 50%;
        margin: 0 auto;
        text-align: center;
        line-height: 1.5rem;
        font-size: 0.7rem;
        color: #999;
      }
    }
  }
}
</style>
